<template>
  <ProjectList />
</template>

<script>
import ProjectList from "../../components/ProjectList/ProjectList.vue";

export default {
  components: {
    ProjectList,
  },
};
</script>

<style lang="">
</style>